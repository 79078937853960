const talks = [
    {
        title: "Dev Scrimmage",
        location: "DenverScript",
        description: "There’s thousands of tutorials, exercises, and projects available to practice writing good code. However, there’s so much more to being a great developer than just writing code. From project management to testing and deployment, it’s good to run through it start to end on occasion. I’ll be using an example to demonstrate how to build your next coding practice into a scrimmage for working as a developer. You can find the example project we'll be using here.",
        date: "05/23/2024",
        videoLink: "https://www.youtube.com/watch?v=ZijN1kegU78&t=2312s",
        videoEmbedLink: "https://www.youtube.com/embed/ZijN1kegU78?si=u0aCVWjTsFy2t9Vy&amp;start=2312",
        resources: [{
            name: "Curricular Practice Project -  Event Card Component",
            url: "https://app.curricular.dev/projects/fe004-event-card-component"
        }],
        slides: [{
            url: "/talks/Dev_Scrimmage/1.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/2.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/3.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/4.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/5.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/6.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/7.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/8.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/9.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/10.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/11.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/12.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/13.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/14.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/15.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/16.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/17.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/18.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/19.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/20.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/21.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/22.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/23.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/24.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/25.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/26.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/27.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/28.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/29.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/30.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/31.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/32.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/33.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/34.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/35.jpg",
            transcript: "",
            alt: "",
        },{
            url: "/talks/Dev_Scrimmage/36.jpg",
            transcript: "",
            alt: "",
        },],
        slidesLink: "",
        slidesBucket: {
            bucketName: "talk-resources",
            path: "dev_scrimmage",
        },
        transcript: ""
    }
]

export default talks;

// Template 
// {
//     title: "",
//     location: "",
//     description: "",
//     date: "",
//     videoLink: "",
//     slidesLink: "",
//     slides: [{
//          url: "/talks/Dev_Scrimmage/1.jpg",
//          transcript: "",
//          alt: "",
//     }],
// resources: [{
//     name: "",
//     url: ""
// }],
//     slidesBucket: {
//         bucketName: "",
//         path: "",
//     },
//     transcript: ""
// }
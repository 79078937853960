import React, { useState } from "react";
import emailjs from "@emailjs/browser";

emailjs.init("nNCq7UpF2PCtotws4");

const sendEmail = (name: string, email: string, message: string) => {
  emailjs.send("service_mbzexqf", "template_ifulbat", {
    from_name: name,
    message: message,
    reply_to: email,
  });
};

const validateEmail = (emailSubmission: string) => {
  // eslint-disable-next-line
  let emailRegex = /[-A-Za-z0-9!#$%&'*+\/=?^_`{|}~]+(?:\.[-A-Za-z0-9!#$%&'*+\/=?^_`{|}~]+)*@(?:[A-Za-z0-9](?:[-A-Za-z0-9]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[-A-Za-z0-9]*[A-Za-z0-9])?/i;
  return emailRegex.test(emailSubmission.trim());
};

type Props = {
  modalOpen: boolean;
  closeModal: () => void;
};

export default function Email({ modalOpen, closeModal }: Props) {
  const [showEmailConfirm, setShowEmailConfirm] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const closeAndResetModal = () => {
    setShowEmailConfirm(false);
    closeModal();
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);

    let errors = [];
    if (!validateEmail(formData.get("email") as string)) {
      errors.push("Must provide valid email address");
      setErrorMessages(errors);
    } else {
      setErrorMessages([]);
      sendEmail(
        formData.get("name") as string,
        formData.get("email") as string,
        formData.get("message") as string,
      );
      setShowEmailConfirm(true);
      document.getElementById("close-button")?.focus();
    }
  };

  return (
    <>
      {modalOpen ? (
        <div className="modal-overlay" onClick={closeAndResetModal}>
          <div
            className="modal-wrapper"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="modal">
              <button
                onClick={closeAndResetModal}
                className="float-right"
                id="close-button"
              >
                <svg
                  width="16pt"
                  height="16pt"
                  version="1.1"
                  viewBox="0 0 1200 1200"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m792 408c-19.199-19.199-49.199-19.199-68.398 0l-123.6 123.6-124.8-123.6c-19.199-18-49.199-18-68.398 1.1992-18 19.199-18 48 0 67.199l124.8 123.6-123.6 124.8c-18 19.199-18 49.199 1.1992 68.398 19.199 18 48 18 67.199 0l123.6-124.8 124.8 123.6c19.199 18 49.199 18 68.398-1.1992 18-19.199 18-48 0-67.199l-124.8-123.6 123.6-124.8c19.199-18 19.199-49.199 0-67.199zm-192-385.2c-319.2 0-577.2 258-577.2 577.2s258 577.2 577.2 577.2 577.2-258 577.2-577.2-258-577.2-577.2-577.2zm0 1058.4c-266.4 0-481.2-216-481.2-481.2s214.8-481.2 481.2-481.2 481.2 216 481.2 481.2-216 481.2-481.2 481.2z" />
                </svg>
              </button>
              <h2 className="mb-5 text-center">Contact Me</h2>
              {showEmailConfirm ? (
                <p className="text-center">Message successfully sent.</p>
              ) : (
                <>
                  {errorMessages.length > 0 && (
                    <ul className="p-3 bg-[var(--pink)] text-[var(--background-rgb)] rounded-sm mb-5">
                      {errorMessages.map((error, index) => {
                        return <li key={index}>{error}</li>;
                      })}
                    </ul>
                  )}
                  <form
                    className="flex flex-col gap-10 w-full"
                    onSubmit={onSubmit}
                  >
                    <label htmlFor="name">
                      Your Name:
                      <input
                        name="name"
                        type="text"
                        className="block border-b rounded border-gray-600 w-full mt-2"
                        required
                      />
                    </label>
                    <label htmlFor="email">
                      Your Email Address:
                      <input
                        name="email"
                        type="text"
                        className="block border-b rounded border-gray-600 w-full mt-2"
                        required
                      />
                    </label>
                    <label htmlFor="message">
                      Message:
                      <textarea
                        name="message"
                        className="block border rounded border-gray-600 w-full h-60 mt-2"
                        required
                      />
                    </label>
                    <button
                      type="submit"
                      className="border rounded p-3 w-fit text-center mx-auto"
                    >
                      Send
                    </button>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

import React from "react";
import {useState} from "react";
import talks from "../data/talks";

function Talks() {
    const [talkList] = useState(talks);

    return (
        <div className="flex flex-col md:p-10 md:p-5 md:p-24 mb-12 md:mb-0 pb-20">
            <header className="text-center"><h1 className="text-[var(--pink)]">Marlena Baker</h1></header>
            <section >
                <h2 className="text-center">Public Speaking</h2>
                {talkList.map((talk, index) => (
                    <article key={index} className="flex flex-col items-center gap-2">
                        <div className="flex flex-col gap-10 justify-between items-center w-full md:max-w-[50%]">
                            <div className="gap-4 flex flex-col p-5 md:p-0">
                                <h3>{talk.title}</h3> 
                                <p>Given at {talk.location} on {talk.date}</p>
                                <p>{talk.description}</p>
                                {talk.resources?.length > 0 ? (
                                    <>
                                        <h4 className="text-[var(--pink)]">Resources</h4>
                                        <ul>
                                            {talk.resources.map((resource, index) => (<li key={index}><a href={resource.url} target="_blank" rel="noreferrer">{resource.name}</a></li>))}
                                        </ul>
                                    </>
                                    ) : (<></>)}
                            </div>
                            <iframe src={talk.videoEmbedLink} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen className="w-[600px] h-[300px] md:max-w-[50vw]"></iframe>
                        </div>
                        <div className="flex flex-col flex-nowrap gap-3 mt-5 w-full">
                        {/* {talk.slides.map((slide, sIndex) => (<div className="flex flex-row gap-3 flex-nowrap">
                            <div>
                                <img src={`.${slide.url}`} alt={slide.alt} className="border-2 md:border-4 w-full md:w-[50vw]" />
                            </div>
                            <div>
                                <p>{slide.transcript || 'Transcript coming soon.'}</p>
                            </div>
                        </div>))} */}
                            <div className="carousel w-full">
                                {talk.slides.map((slide, index) => (
                                    <div key={index} id={`item${index}`} className="carousel-item w-full md:gap-5 flex justify-center">
                                        <img src={slide.url} alt={slide.alt} className="w-full max-w-screen md:max-h-screen md:w-[50vw]" />
                                        {/* <div>
                                            <p>{slide.transcript || 'Transcript coming soon.'}</p>
                                        </div> */}
                                    </div> 
                                    
                                ))}
                            </div> 
                        </div>
                        <div className="flex flex-row flex-wrap justify-center w-full md:w-[50vw] py-2 gap-2">
                        {talk.slides.map(((slide, index) => (
                            <a key={index} href={`#item${index}`} className="px-1">{index+1}</a> 
                        )))}
                        </div>
                    </article>
                ))}
            </section>
        </div>
    )
}

export default Talks;
export const skills = [
    ["C#", "Kotlin", "PHP", "JavaScript"],
    ["Vue.js", "React", "Blazor", "Express", "Spring", "Node.js"],
    ["PostgreSQL", "MongoDB"],
    ["Jest", "XUnit", "PHPUnit", "Playwright"],
    [
      "User Experience",
      "A11y",
      "Technical Training",
      "Mentoring",
      "Public Speaking",
      "Project Leadership",
      "Technical SEO",
      "Observability",
    ],
  ];
  
  export const experience = [
    {
      role: "Senior Fullstack Engineer",
      company: "App Academy",
      dates: "March 2022-March 2023",
      description: [
        "Built three robust, user-focused applications",
        "Improved performance of high value pages",
        "Mentored Mid and Junior level engineers",
        "Contributed to architectural decision making",
        "Introduced and iterated on team processes",
        "Collaborated with product to define feature needs",
        "Owned the user experience design of our customer facing applications",
        "Trained team member on design and A11y",
        "Led a weekly meeting dedicated to learning new things and presenting on technical topics",
      ],
    },
    {
      role: "Fullstack Engineer",
      company: "Quizlet",
      dates: " September 2019-February 2022",
      description: [
        "Contributed to all layers of the application",
        "Improved UX and site performance",
        "Led tech wealth and product feature projects",
        "Collaborated across teams to deliver features",
        "Innovated on SEO strategy",
      ],
    },
    {
      role: "Frontend Engineer",
      company: "HomeAdvisor",
      dates: "June 2018-August 2019",
      description: [
        "Led efforts to convert the front end architecture to Vue.js",
        "Scoped, planned and delivered complex applications",
        "Wrote and executed on a new front end interview process",
        "Trained other employees on technical skills and training",
        "Mentored junior developers to promotion",
      ],
    },
  ];
  
  export const projects = [
    {
      name: "Career Track Mentorship Pilot",
      description:
        "Pitched and planned a one year pilot for internal career mentoring for underrepresented groups in tech",
    },
    {
      name: "Little Bird",
      description:
        "Scheduling app for parents and teachers. Gathered parent availability via Twillio chat bot and set meeting time programatically. Web dashboard for teachers to view progress and full chat transcripts.",
    },
  ];
  
  export const community = [
    {
      role: "Co-Organizer",
      name: "DenverScript",
      dates: "June 2023 - Present",
      description:
        "Plan and host meetups, find and support speakers, present about relevant topics",
    },
    {
      role: "Volunteer",
      name: "Denver Botanic Gardens",
      dates: "July 2023 - Present",
      description:
        "Garden upkeep and planning at Plains CC; Assist with tissue culture lab work",
    },
    {
      role: "DEI Committee Memeber",
      name: "App Academy",
      dates: "June 2022 - March 2023",
      description:
        "Shared knowledge and brainstormed ways to work towards App Academy's DEI goals",
    },
  ];
  
  export const education = [
    {
      result: "Web Development Immersive",
      institution: "Galvanize Inc",
      dateCompleted: "2017",
    },
    {
      result: "BFA, Communication Design",
      institution: "Metropolitan State University of Denver",
      dateCompleted: "2011",
    },
  ];
  
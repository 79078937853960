import React from "react";
import { useState } from "react";
import Email from "../email";
import {
  skills,
  experience,
  community,
  projects,
  education,
} from "../data/resume-short";

import "./styles/Resume.css";

function Resume() {
    const [emailModalOpen, setEmailModalOpen] = useState(false);
//   const [extendExperience, setExtendExperience] = useState(false);

  const closeModal = () => {
    setEmailModalOpen(false);
  };
  
  const openModalKeydown = (e: React.KeyboardEvent<HTMLButtonElement>): void => {
    if (e.key === "Enter") setEmailModalOpen(true);
  }

  const openModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    setEmailModalOpen(true);
  };



  return (
    <>
      <div className="width-full flex flex-col flex-nowrap items-center">
        <div
          className={`flex min-h-screen flex-col p-10 md:p-5 md:p-24 md:overflow-hidden max-w-7xl mb-12 md:mb-0 ${
            emailModalOpen ? "h-screen" : ""
          }`}
        >
          <header className="flex md:flex-row justify-around mb-5 md:mb-20 flex-col gap-3 md:gap-0">
            <div className="flex flex-col gap-3">
              <span className="align-middle">
                <span className="circle mr-3 md:mr-1">
                  <svg
                    width="20"
                    height="20"
                    version="1.1"
                    viewBox="0 0 1200 1200"
                    xmlns="http://www.w3.org/2000/svg"
                    role="graphics-symbol"
                  >
                    <title>Location</title>
                    <desc>teardrop map marker icon in a pink circle</desc>
                    <path
                      d="m600 230.73c142.32 0 214.61 173.16 113.54 274.15-62.855 62.809-164.28 62.832-227.14 0.023437-101.06-100.99-28.727-274.18 113.59-274.18m414.55 57.168c-59.977-178.15-238.96-287.02-417.21-287.9-178.46-0.88672-356.33 106.27-413.79 284.45-100.54 311.61 208.9 691.96 420.7 915.55 212.57-224.02 515.19-600.5 410.3-912.1zm-417.55-219.74c152.28 0.76953 303.24 93.84 352.95 241.51 88.871 264-174.19 601.51-345.67 790.2-182.71-201.19-439.44-535.41-355.87-794.47 47.613-147.62 197.38-238.01 348.6-237.24zm164.71 484.95c144.14-144.07 40.73-390.5-161.71-390.5s-305.88 246.41-161.76 390.53c89.473 89.352 234.03 89.352 323.47-0.023437z"
                      fill-rule="evenodd"
                    />
                  </svg>
                </span>
                Denver, CO
              </span>
              <span>
                <span className="circle mr-3 md:mr-1">
                  <svg
                    width="20"
                    height="20"
                    version="1.1"
                    viewBox="0 0 1200 1200"
                    xmlns="http://www.w3.org/2000/svg"
                    role="graphics-symbol"
                  >
                    <title>Contact</title>
                    <desc>evelope icon in a pink circle</desc>
                    <path d="m1009.7 242.52h-820.15c-71.641 0-129.59 58.703-129.59 130.34v454.74c0 71.641 57.949 129.88 129.59 129.88h820.15c71.637 0.003907 130.26-58.234 130.26-129.87v-454.74c0-71.641-58.621-130.34-130.26-130.34zm0.54297 65.641-364.56 364.11c-12.133 12.133-28.32 18.457-45.602 18.457h-0.16797c-17.34 0-33.551-6.3242-45.672-18.562-31.855-32.164-268.88-271.96-362.71-363.76h818.17c0.18359 0 0.36328-0.23828 0.54297-0.23828zm64.918 519.45c0 35.82-29.641 65.074-65.461 65.074h-820.15c-35.82 0-64.789-29.258-64.789-65.074v-454.74c0-11.496 2.9297-22.285 8.1836-31.656 62.398 61.391 247.36 247.81 375.18 376.87 24.359 24.602 56.879 37.441 91.621 38.52h0.33594c34.621 0 67.117-13.656 91.523-38.062l375.9-375.64c4.7266 9 7.6562 19.117 7.6562 29.977z" />
                  </svg>
                </span>
                <button
                  type="button"
                  onClick={openModal}
                  onKeyUp={openModalKeydown}
                  className="cursor-pointer"
                  tabIndex={0}
                >
                  Contact
                </button>
                <Email
                  modalOpen={emailModalOpen}
                  closeModal={closeModal}
                />
              </span>
            </div>
            <h1 className="order-first md:order-none">Marlena Baker</h1>
            <div className="flex flex-col gap-3">
              <span>
                <span className="circle mr-3 md:mr-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    role="graphics-symbol"
                  >
                    <title>LinkedIn icon</title>
                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                  </svg>
                </span>
                LinkedIn:{" "}
                <a
                  href="https://www.linkedin.com/in/marlenabaker/"
                  target="_blank"
                  rel="noreferrer"
                >
                  marlenabaker
                </a>
              </span>
              <span>
                <span className="circle mr-3 md:mr-1">
                  <svg
                    width="20"
                    height="20"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 100"
                    role="graphics-symbol"
                  >
                    <title>Github icon</title>
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M48.854 0C21.839 0 0 22 0 49.217c0 21.756 13.993 40.172 33.405 46.69 2.427.49 3.316-1.059 3.316-2.362 0-1.141-.08-5.052-.08-9.127-13.59 2.934-16.42-5.867-16.42-5.867-2.184-5.704-5.42-7.17-5.42-7.17-4.448-3.015.324-3.015.324-3.015 4.934.326 7.523 5.052 7.523 5.052 4.367 7.496 11.404 5.378 14.235 4.074.404-3.178 1.699-5.378 3.074-6.6-10.839-1.141-22.243-5.378-22.243-24.283 0-5.378 1.94-9.778 5.014-13.2-.485-1.222-2.184-6.275.486-13.038 0 0 4.125-1.304 13.426 5.052a46.97 46.97 0 0 1 12.214-1.63c4.125 0 8.33.571 12.213 1.63 9.302-6.356 13.427-5.052 13.427-5.052 2.67 6.763.97 11.816.485 13.038 3.155 3.422 5.015 7.822 5.015 13.2 0 18.905-11.404 23.06-22.324 24.283 1.78 1.548 3.316 4.481 3.316 9.126 0 6.6-.08 11.897-.08 13.526 0 1.304.89 2.853 3.316 2.364 19.412-6.52 33.405-24.935 33.405-46.691C97.707 22 75.788 0 48.854 0z"
                    />
                  </svg>
                </span>
                Github:{" "}
                <a href="https://github.com/baker-marlena" target="_blank" rel="noreferrer">
                  baker-marlena
                </a>
              </span>
            </div>
          </header>
          <section className="gap-3 md:gap-10 flex flex-col md:flex-row">
            <section className="w-full md:w-fit whitespace-nowrap text-center md:text-left">
              <h2>Skills</h2>
              {skills.map((skillList, index) => (
                <ul key={`${index}-$${skillList.length}`} className="mb-5">
                  {skillList.map((skill) => (
                    <li key={skill}>{skill}</li>
                  ))}
                </ul>
              ))}
            </section>
            <section className="w-full md:w-1/2">
              <div className="flex">
                <h2>Experience</h2>
                {/* <button type="button" className="border-none" onClick={() => {setExtendExperience(!extendExperience)}}><img className="ml-3 w-4 h-auto" src={extendExperience ? "./images/noun-collapse-2571253.svg" : "./images/noun-expand-2571312.svg"} /></button> */}
              </div>
              {/* {extendExperience ? fullExperience.map((exp) => (
                <article key={exp.role}>
                  <h3>{exp.role}</h3>
                  <p className="pink-text">
                    {exp.company} {exp.dates}
                  </p>
                  <ul className="list-disc">
                    {exp.description.map((item) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                </article>
              )) :  */}
              {experience.map((exp) => (
                <article key={exp.role}>
                  <h3>{exp.role}</h3>
                  <p className="pink-text">
                    {exp.company} {exp.dates}
                  </p>
                  <ul className="list-disc">
                    {exp.description.map((item) => (
                      <li key={item}>{item}</li>
                    ))}
                  </ul>
                </article>
              ))}
            </section>
            <div className="flex flex-col w-full md:w-1/2 gap-3 md:gap-0">
              <section className="col-span-2 col-start-7 row-start-1">
                <h2>Projects</h2>
                {projects.map((project) => (
                  <article key={project.name}>
                    <h3>{project.name}</h3>
                    <p>{project.description}</p>
                  </article>
                ))}
              </section>
              <section className="col-span-2 col-start-7 row-start-2">
                <h2>Community</h2>
                {community.map((comm) => (
                  <article key={comm.name}>
                    <h3>{comm.name}</h3>
                    <p className="pink-text">{comm.dates}</p>
                    <p>{comm.description}</p>
                  </article>
                ))}
              </section>
              <section className="col-span-2 col-start-7 row-start-3">
                <h2>Education</h2>
                {education.map((edu) => (
                  <article key={edu.institution}>
                    <h3>{edu.result}</h3>
                    <p className="pink-text">
                      {edu.institution}, {edu.dateCompleted}
                    </p>
                  </article>
                ))}
              </section>
            </div>
          </section>
          
        </div>
      </div>
    </>
  );
}

export default Resume;
import React from "react";
import { useCallback, useEffect, useState } from "react";
import { Outlet, Link } from "react-router-dom";

import './App.css';

function App() {
  const [isDarkMode, setIsDarkMode] = useState<boolean|undefined>(undefined);


  const setTheme = useCallback((dark: boolean) => {
    if (dark) {
      localStorage.setItem("theme", "dark");
      document.getElementsByTagName("html")[0].classList.add("dark");
      setIsDarkMode(true);
    } else {
      localStorage.setItem("theme", "light");
      document.getElementsByTagName("html")[0].classList.remove("dark");
      setIsDarkMode(false);
    }
  }, [setIsDarkMode]);

  useEffect(() => {
    let isDark: boolean|undefined = isDarkMode;
    if (isDark === undefined) isDark = localStorage.getItem("theme") === "dark";
    if (isDark === undefined)
      isDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
    setIsDarkMode(isDark);
    setTheme(isDark);
  }, [isDarkMode, setTheme]);


  const toggleThemeKeydown = (e: React.KeyboardEvent<HTMLButtonElement>): void => {
    if (e.key !== "Enter") setTheme(e.currentTarget?.name === "Dark Mode");

  }

  const toggleTheme = (e: React.MouseEvent<HTMLButtonElement>) => {
    setTheme(e.currentTarget?.name === "Dark Mode");
  };

  return (
    <>
      <header className="bg-[var(--foreground-rgb)] text-[var(--background-rgb)] p-2">
        <nav>
          <ul className="flex flex-row justify-around flex-nowrap">
            <li><Link to="/">Resume</Link></li>
            <li><Link to="/talks">Talks</Link></li>
          </ul>
        </nav>
      </header>
      <main>
        <Outlet />
      </main>
      <footer className="flex flex-row flex-nowrap justify-around md:justify-start bg-[var(--background-rgb)] md:bg-transparent fixed w-screen z-15 left-0 bottom-0 p-5">
            <div className="bg-zinc-500 w-min rounded-3xl p-1 flex gap-2 justify-center md:justify-around md:justify-start ">
              <button
                className={`circle ${isDarkMode ? "unselected" : ""}`}
                type="button"
                disabled={!isDarkMode}
                onClick={toggleTheme}
                onKeyUp={toggleThemeKeydown}
                name="Light Mode"
                title={`Sun icon in ${
                  !isDarkMode ? "pink" : "gray"
                } circle, light mode is ${!isDarkMode ? "active" : "inactive"}`}
              >
                <svg
                  width="20pt"
                  height="20pt"
                  version="1.1"
                  viewBox="0 0 1200 1200"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <path d="m732.5 467.5c-71-71-194.5-71-265 0-35.5 35.5-55 82.5-55 132.5s19.5 97 55 132.5 82.5 55 132.5 55 97-19.5 132.5-55 55-82.5 55-132.5-19.5-97-55-132.5zm-53 212c-42.5 42.5-116.5 42.5-159 0-21.5-21-33-49.5-33-79.5s11.5-58.5 33-79.5c21-21 49.5-33 79.5-33s58.5 11.5 79.5 33 33 49.5 33 79.5-11.5 58.5-33 79.5z" />
                    <path d="m812 425.5c9.5 0 19-3.5 26.5-11l106-106c14.5-14.5 14.5-38.5 0-53s-38.5-14.5-53 0l-106 106c-14.5 14.5-14.5 38.5 0 53 7.5 7.5 17 11 26.5 11z" />
                    <path d="m838.5 785.5c-14.5-14.5-38.5-14.5-53 0s-14.5 38.5 0 53l106 106c7.5 7.5 17 11 26.5 11s19-3.5 26.5-11c14.5-14.5 14.5-38.5 0-53z" />
                    <path d="m361.5 785.5-106 106c-14.5 14.5-14.5 38.5 0 53 7.5 7.5 17 11 26.5 11s19-3.5 26.5-11l106-106c14.5-14.5 14.5-38.5 0-53s-38.5-14.5-53 0z" />
                    <path d="m361.5 414.5c7.5 7.5 17 11 26.5 11s19-3.5 26.5-11c14.5-14.5 14.5-38.5 0-53l-106-106c-14.5-14.5-38.5-14.5-53 0s-14.5 38.5 0 53z" />
                    <path d="m600 337.5c20.5 0 37.5-17 37.5-37.5v-150c0-20.5-17-37.5-37.5-37.5s-37.5 17-37.5 37.5v150c0 20.5 17 37.5 37.5 37.5z" />
                    <path d="m1050 562.5h-150c-20.5 0-37.5 17-37.5 37.5s17 37.5 37.5 37.5h150c20.5 0 37.5-17 37.5-37.5s-17-37.5-37.5-37.5z" />
                    <path d="m600 862.5c-20.5 0-37.5 17-37.5 37.5v150c0 20.5 17 37.5 37.5 37.5s37.5-17 37.5-37.5v-150c0-20.5-17-37.5-37.5-37.5z" />
                    <path d="m337.5 600c0-20.5-17-37.5-37.5-37.5h-150c-20.5 0-37.5 17-37.5 37.5s17 37.5 37.5 37.5h150c20.5 0 37.5-17 37.5-37.5z" />
                  </g>
                </svg>
              </button>
              <button
                className={`circle ${isDarkMode ? "" : "unselected"}`}
                type="button"
                disabled={isDarkMode}
                onClick={toggleTheme}
                onKeyUp={toggleThemeKeydown}
                name="Dark Mode"
                title={`Moon icon in ${
                  isDarkMode ? "pink" : "gray"
                } circle, dark mode is ${isDarkMode ? "active" : "inactive"}`}
              >
                <svg
                  width="20pt"
                  height="20pt"
                  version="1.1"
                  viewBox="0 0 1200 1200"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="m355.12 101.7c-6.3477 0.20312-12.535 2.0117-17.992 5.2578-162.39 95.715-262.12 270.29-262.13 458.79 0 293.7 238.88 532.56 532.56 532.56 243.46 0.007812 456.32-165.51 516.23-401.5 8.1602-32.277-26.656-58.391-55.359-41.52-70.395 41.496-150.66 63.383-232.38 63.387-253.15 0-457.55-204.51-457.55-457.69-0.003907-37.973 4.6367-75.707 13.977-112.52 6.1719-24.137-12.465-47.469-37.367-46.781zm-49.27 140.06c-0.23437 6.4766-1.0352 14.801-1.0352 21.289 0 293.7 237.56 530.65 531.24 530.65 62.055-0.003907 122.04-14.613 179.92-35.57-74.426 157.7-228.81 265.18-408.42 265.17-253.15 0-457.55-204.38-457.55-457.55 0.003907-128.02 64.582-238.5 155.84-323.99z"
                    fill-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </footer>
    </>
  )
}

export default App;
